<template>
	<div class="role-manage">
		<Header :title="customerName"></Header>
		<div class="search">
			<div class="left">
				<span class="name">角色名称</span>
				<Input maxlength="20" show-word-limit v-model="keyword" placeholder="请输入" style="width: 300px" />
			</div>
			<div class="right">
				<Button class="btn" type="default" @click="handleSearch">查询</Button>
				<Button class="btn" type="primary" @click="createRole">新建角色</Button>
			</div>
		</div>
		<Table class="table" tooltip-theme="light" border :columns="columns" :data="data" @on-select="handleRowSelect">
			<template slot-scope="{ row }" slot="option">
				<Button class="optionBtn" size='small' type="primary" @click="upDataRole(row)">查看/编辑</Button>
				<Button class="optionBtn" size='small' type="primary" @click="roleAuth(row)">授权</Button>
				<ComfirmBtn title='温馨提示' style="margin-left: 5px;" info='删除' size='small' type="error" @comfirm='deleteRole(row)'>
					<div slot="content">
						<div style="font-size: 18px;text-align: center;">
							请确认删除<span style="color: red;">{{row.roleName}}</span>这一角色？
						</div>
					</div>
				</ComfirmBtn>
			</template>
		</Table>
		<Page :total="total" style="margin: 20px 0; float: right" @on-change="pageChange" />

		<CreateModal @done="getList" :isEdit="isEdit" :id="selectedRole.id" :roleList="roleList"></CreateModal>
		<!-- 		<EditAuthModal :dataAuth="dataAuthList" :funcAuth="funcAuthList" :reportAuth="reportAuthList"
			:show="isShowEditAuthModal" :roleName="selectedRole.roleName" :roleId="selectedRole.id"
			:customerName="customerName"></EditAuthModal> -->
		<AuthModal :role='role'></AuthModal>
	</div>
</template>

<script>
	import Header from "./component/Header";
	import CreateModal from "./modal/roleManage/createModal";
	import AuthModal from "./modal/roleManage/AuthModal";
	import ComfirmBtn from "@/components/Comfirm";
	import {
		getRoleList,
		deleteRoleApi,
		funcAuthApi,
		dataAuthTreeApi,
		funcAuthIdApi,
		reportAuthApi,
		reportAuthIdApi,

	} from "@/api/roleAndAccount";
	import {
		getDetail
	} from "@/api/MyAccount";
	import {
		formatDate
	} from "@/utils/";

	export default {
		name: "myAccount",
		components: {
			Header,
			CreateModal,
			AuthModal,
			ComfirmBtn
		},
		data() {
			const self = this;

			return {
				name: "",
				columns: [{
						type: "selection",
						width: 60,
						align: "center",
					},
					{
						title: "编号",
						key: "id",
						align: "center",
						width: 108,
					},
					{
						title: "角色名称",
						key: "roleName",
						align: "center",
					},
					// {
					// 	title: "角色状态",
					// 	key: "state",
					// 	align: "center",
					// 	render: (h, params) => {
					// 		return h("span", {}, params.row.state === 0 ? "不可用" : "可用");
					// 	},
					// },
					{
						title: "描述",
						key: "roleDesc",
						align: "center",
					},
					{
						title: "创建时间",
						key: "createTime",
						align: "center",
					},
					{
						title: "更新时间",
						key: "updateTime",
						align: "center",
					},
					{
						title: "操作",
						key: "option",
						width: 261,
						align: "center",
						slot: 'option',
					},
				],
				data: [],

				modalTitle: "",

				current: 0,
				size: 15,
				keyword: "",
				selectedRow: [],
				selectedRole: {},
				isEdit: false,
				total: 0,
				dataAuthList: [],
				funcAuthList: [],
				reportAuthList: [],
				customerName: "",
				roleList: [],
				role: {},
			};
		},
		computed: {
			isShowRoleCreateModal: {
				get() {
					return this.$store.state.personal.isShowRoleCreateModal;
				},
				set(val) {
					this.$store.state.personal.isShowRoleCreateModal = val;
				},
			},
			canShowAuthModal: {
				get() {
					return this.$store.state.personal.canShowAuthModal;
				},
				set(val) {
					this.$store.state.personal.canShowAuthModal = val;
				},
			},
			userId() {
				return this.$store.state.userId;
			},
			customerInfo() {
				let str = localStorage.getItem('customerInfo')
				let obj = JSON.parse(str)
				return obj
			},
		},
		watch: {
			isShowRoleCreateModal(val) {
				if (!val) {
					this.isEdit = false;
				}
			},
		},
		beforeMount() {
			this.$Notice.destroy();
		},
		mounted() {
			this.getAccount();
		},
		methods: {
			upDataRole(row) {
				this.isEdit = true;
				this.selectedRole = row;
				this.isShowRoleCreateModal = true;
			},


			createRole() {
				this.isShowRoleCreateModal = true;
			},
			deleteRole(row) {
				// const selectedId = this.selectedRow.map((item) => item.id);
				deleteRoleApi({
					id: row.id
				}).then((res) => {
					this.$msg.success({
						text: "删除成功"
					});
					this.getList();
				}).catch(err => {
					if (err.code == -1) {
						this.$msg.error({
							text: err.data
						});
					}
					this.getList();
				})
			},
			getAccount() {
				getDetail(this.userId)
					.then((res) => {
						this.customerName = res.customerName;
						if (res.userType != 1) {
							//当检测到用户没有权限到时候到操作
							this.$router.push({
								path: "/PersonalCenter"
							});
							this.$msg.error({
								type: "noAuthority",
								code: 1005
							});
						} else {
							this.getList();
						}
					})
					.catch((err) => {
					});
			},
			getList() {
				getRoleList({
						current: this.current,
						size: this.size,
						customerId: this.customerInfo.id,
						q: this.keyword,
					})
					.then((res) => {
						this.data = res.records;
						this.data.forEach((item) => {
							this.roleList.push(item.roleName)
							item.createTime = formatDate(item.gmtCreate);
							item.updateTime = formatDate(item.gmtUpdated);
						});
						this.total = Math.ceil(res.total / this.size) * 10;
					})
					.catch((err) => {
					});
			},
			handleSearch() {
				this.getList();
			},
			handleRowSelect(select, row) {
				this.selectedRow = select;
			},
			pageChange(page) {
				this.current = page - 1;
				this.getList();
			},

			roleAuth(row) {
				this.canShowAuthModal = true
				this.role = row
			},

			findCheckedNode(data, checkedList) {
				for (let i = 0; i < data.length; i++) {
					if (checkedList.includes(data[i].id) && !data[i].children) {
						data[i].checked = true;
					}

					if (data[i].children && data[i].children.length > 0) {
						this.findCheckedNode(data[i].children, checkedList);
					}
				}

				let flag = false;
				for (let i = 0; i < data.length; i++) {
					if (data[i].children && data[i].children.length > 0) {
						flag = data[i].children.every((child) => child.checked);
						data[i].checked = flag;
						let list = data[i].children
						list.forEach((i, index) => {
							if (i.id == 96) {
								list.splice(index, 2)
							}
							// if(i.id == 96 || i.id == 97){
							//   i.disabled = true
							// }
						})

					}
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.role-manage {
		width: 100%;
		padding: 0 20px;

		.search {
			padding: 25px 0;
			display: flex;

			.left {
				margin-right: 80px;

				.name {
					margin-right: 20px;
				}
			}

			.right {
				.btn {
					width: 114px;
					margin-right: 20px;
				}
			}
		}

		.table {
			& /deep/ .ivu-table-cell {
				padding-right: 10px;
				padding-left: 10px;
			}
		}

		.optionBtn {
			margin-left: 5px;
		}
	}
</style>
