<template>
    <div class="header">
        <h2>{{ title }}</h2>
    </div>
</template>

<script>
export default {
    name: 'personalHeader',
    props: {
        title: String
    }
};
</script>

<style lang="scss" scoped>
.header {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #d6d7d9;
}
</style>
