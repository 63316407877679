<template>
	<Modal v-model="show" footer-hide class="create-modal" class-name="vertical-center-modal" :width="700"
		:closable="true" :title="isEdit?'角色编辑':'新建角色'">
		<div class="modal-content">
			<Form :model="createForm" :label-width="80" class="auth-form" :rules="createRules" ref="createForm">
				<FormItem label="角色名称" prop="roleName">
					<Input maxlength="20" show-word-limit v-model="createForm.roleName" placeholder="请输入"
						style="width: 306px; margin-right: 10px; padding: 0"></Input>
				</FormItem>
			<!-- 	<FormItem class="status-item" label="角色状态" prop="state">
					<RadioGroup v-model="createForm.state">
						<Radio :label="1">可用</Radio>
						<Radio :label="0">不可用</Radio>
					</RadioGroup>
				</FormItem> -->
				<FormItem label="描述" prop="roleDesc">
					<Input maxlength="100" show-word-limit type="textarea" v-model="createForm.roleDesc"
						style="width: 306px; margin-right: 10px; padding: 0" placeholder="请输入" :rows="4"></Input>
				</FormItem>

				<FormItem style="text-align: center;margin-top: 40px;" :label-width="0">
					<Button type="info" style="width: 140px;height: 40px;"
						@click="handleSubmit('createForm')">确定</Button>
					<Button type="default" class="cancel" style="width: 144px;margin-left: 10px;height: 40px;"
						@click="handleCancel">取消</Button>
				</FormItem>
			</Form>
		</div>
	</Modal>
</template>

<script>
	import {
		saveOrUpdate,
		getRoleDetail
	} from "@/api/roleAndAccount";

	export default {
		name: "createModal",
		props: {
			title: {
				type: String,
				default: "",
			},
			isEdit: Boolean,
			id: Number,
			roleList: Array
		},
		data() {
			return {
				createForm: {
					roleName: "",
					roleDesc: "",
					// state: 1,
				},
				createRules: {
					roleName: [{
						required: true,
						message: "此项必填",
						trigger: "blur"
					}],
					// state: [{
					// 	required: true,
					// 	message: "此项必填",
					// }],
				},
			};
		},
		computed: {
			show: {
				get() {
					return this.$store.state.personal.isShowRoleCreateModal;
				},
				set(val) {
					this.$store.state.personal.isShowRoleCreateModal = val;
				},
			},
		},
		watch: {
			show(val) {
				if (val && this.isEdit) {
					getRoleDetail({
						id: this.id
					}).then((res) => {
						this.createForm.roleName = res.roleName;
						this.createForm.roleDesc = res.roleDesc;
						// this.createForm.state = res.state;
					});
				} else {
					this.createForm.roleName = ''
					this.createForm.roleDesc = ''
					// this.createForm.state = 1
				}
			},
		},
		methods: {
			handleSubmit(name) {
				this.$refs[name].validate((valid) => {
					if (valid) {
						const params = {
							roleCreater: this.$store.state.userId,
							roleName: this.createForm.roleName,
							roleDesc: this.createForm.roleDesc,
							// state: this.createForm.state,
						};
						if (!this.createForm.roleName) {
							this.$msg.error({
								text: ["请输入角色名称"]
							});
							return;
						}
						if (this.isEdit) {
							params.id = this.id;
						}
						saveOrUpdate(params).then((res) => {
							// this.$msg.success(this.isEdit ? '修改成功' : '创建成功');
							this.$msg.success({
								text: this.isEdit ? "编辑成功" : "创建成功"
							});
							this.show = false;
							this.$emit("done");
						}).catch(err => {
							if (err.code == -1) {
								this.$msg.error({
									text: err.data
								});
							} else {
								this.$msg.error({
									text: this.$errorCode[err.code]
								});
							}
						})
					} else {
						//   this.$msg.error("信息不全");
						this.$msg.error({
							type: "remind",
							code: 4033
						});
					}
				});
			},
			handleCancel() {
				this.show = false;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.create-modal {
		/deep/ .vertical-center-modal {
			display: flex;
			align-items: center;
			justify-content: center;

			.ivu-modal {
				top: 0;
			}

			.ivu-modal-body {
				padding: 0;
				height: 100%;
			}

			.head {
				width: 100%;
				padding-top: 20px;
				padding-left: 20px;
				display: flex;
				align-items: center;

				.icon {
					display: inline-block;
					width: 4px;
					height: 23px;
					background-color: #00b6ff;
					position: relative;

					&::after {
						content: "";
						border: 4px solid transparent;
						border-left-color: #00b6ff;
						position: absolute;
						top: 50%;
						right: -7px;
						margin-top: -4px;
					}
				}

				.text {
					margin-left: 6px;
					font-size: 18px;
					font-weight: bold;
					line-height: 20px;
					color: #333;
				}
			}

			.content {




				.auth-form {
					width: 100%;

					/deep/ .ivu-form-item {
						margin-bottom: 20px;

						&.status-item {
							.ivu-form-item-content {
								.ivu-radio-group {
									width: 100%;

									.ivu-radio-group-item {
										margin-right: 40px;
									}
								}
							}
						}
					}
				}
			}

			.cancel:focus {
				box-shadow: none;
			}
		}
	}

	.modal-content {
		padding: 40px 130px;
	}
</style>
