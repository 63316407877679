<template>
	<Modal v-model="show" footer-hide class="edit-auth-modal" class-name="vertical-center-modal" :width="700"
		:closable="false" :mask-closable='false' title='授权'>
		<div class="con">
			<Form ref="addRolePermissionForm" :label-width="90" :model="addRolePermissionForm" inline>
				<FormItem label="角色名称" prop="roleName">
					{{addRolePermissionForm.roleName}}
				</FormItem>
				<br>
				<FormItem label="账号状态" prop="state">
					{{addRolePermissionForm.state |formatState}}
				</FormItem>
			</Form>
			<div style="width: 100%;">
				<Collapse v-model="value">
					<Panel name="1">
						平台功能
						<div slot="content">
							<Tree ref="resourceData" :data="resourceData" expand-node show-checkbox multiple></Tree>
						</div>
					</Panel>
					<Panel name="2">
						报表
						<div slot="content">
							<Tree ref="dataFormData" :data="dataFormData" expand-node show-checkbox multiple></Tree>
						</div>
					</Panel>
				<!-- 	<Panel name="3">
						数据下载量
						<div slot="content">

							<Table ref="downTable" stripe :columns="dataDownColumns" :data="dataDownData">
								<template slot-scope="{ row }" slot="rowLimited">
									<Input style="margin-top: 5px;" type="text" v-model="row.rowLimited"
										placeholder="下载限制" @on-blur='limitOne(row)'></Input>
									<span class="label">最大下载量：{{row.maxRowLimited}}</span>
								</template>
							</Table>
						</div>
					</Panel> -->
				</Collapse>
			</div>
			<div class="btnBox" style="margin-top: 40px;text-align: center;">
				<Button :loading='submitLoading' style="width: 140px;height: 40px;" type="info"
					@click="authorization">授权</Button>
				<Button type="default" style="margin-left: 10px;width: 140px;height: 40px;" @click="cancle">取消</Button>
			</div>
		</div>
	</Modal>
</template>

<script>
	import {
		resource_tree_for_role,
		grant_role
	} from "@/api/roleAndAccount";
	export default {
		name: "AuthModal",
		props: {
			role: Object
		},
		filters: {
			formatState: function(val) {
				if (val == 0) {
					return '不可用'
				} else if (val == 1) {
					return '可用'
				}
			},
		},
		computed: {
			show: {
				get() {
					return this.$store.state.personal.canShowAuthModal;
				},
				set(val) {
					this.$store.state.personal.canShowAuthModal = val;
				},
			},
		},
		watch: {
			show(val) {
				if (val) {
					this.init()
				}
			},
		},
		data() {
			return {
				value: '',
				addRolePermissionForm: {
					roleName: '',
					type: '',
					id: '',
					customerId: ''
				},
				resourceData: [],
				dataFormData: [],
				dataDownData: [],
				submitLoading: false,
				dataDownColumns: [{
						title: "数据下载点",
						key: "dataName",
						ellipsis: true,
						tooltip: true,
						width: "200",
					},
					{
						title: "模块",
						key: "moduleName",
						ellipsis: true,
						tooltip: true,
						width: "100",
					},
					{
						title: "子模块",
						key: "subBlockName",
						ellipsis: true,
						tooltip: true,
						width: "200",
					},
					{
						title: "所在页面url",
						key: "pageUri",
						ellipsis: true,
						tooltip: true,
						width: "200",
					},
					{
						title: "下载限制",
						key: "rowLimited",
						slot: 'rowLimited'
					},
				],
			}
		},
		methods: {
			limitOne(row) {
				if (row.rowLimited <= row.maxRowLimited) {
					this.dataDownData.forEach(item => {
						if (item.index == row.index) {
							item.rowLimited = row.rowLimited
						}
					})
				} else {
					this.$msg.error({
						text: "超出下载量限制"
					});
					this.dataDownData.forEach(item => {
						if (item.index == row.index) {
							item.rowLimited = row.maxRowLimited
						}
					})
					row.rowLimited = row.maxRowLimited
				}
			},
			init() {
				if (this.role.roleName) {
					this.addRolePermissionForm.roleName = this.role.roleName
					this.addRolePermissionForm.type = this.role.type
					this.addRolePermissionForm.id = this.role.id
					this.addRolePermissionForm.customerId = this.role.customerId
					this.addRolePermissionForm.state = this.role.state

				}
				this.getTree(this.addRolePermissionForm.customerId, this.addRolePermissionForm.id)
			},
			async getTree(customerId, roleId) {
				let params = {
					customerId: customerId,
					roleId: roleId
				}
				let data = await resource_tree_for_role(params)
				this.resourceData = data.resource
				this.dataDownData = data.dataDown
				this.dataFormData = data.dataForm

				//数据塞入packageId,dataId
				this.dataDownData.forEach((item, index) => {
					item.roleId = roleId
					item.index = index
				})
			},
			async authorization() {

				//平台功能参数处理
				this.submitLoading = true
				let getResourceDataCheckedNodes = this.$refs.resourceData.getCheckedAndIndeterminateNodes()
				let submitResourceDataCheckedNodes = []
				getResourceDataCheckedNodes.forEach(item => {
					let obj = {}
					obj.resourceId = item.id
					obj.isValid = 1
					obj.packageId = this.addRolePermissionForm.id
					submitResourceDataCheckedNodes.push(obj)
				})


				//报表参数处理
				let getDataFormDataCheckedNodes = this.$refs.dataFormData.getCheckedAndIndeterminateNodes()
				let submitDataFormDataCheckedNodes = []
				getDataFormDataCheckedNodes.forEach(item => {
					let obj = {}
					obj.indexId = item.id
					obj.parentId = item.pid
					obj.indexName = item.title
					obj.fineReportUrl = item.fineReportUrl
					obj.packageId = this.addRolePermissionForm.id
					obj.openNo = item.openNo
					submitDataFormDataCheckedNodes.push(obj)
				})





				//参数收集
				let params = {
					dataForm: submitDataFormDataCheckedNodes,
					dataDown: this.dataDownData,
					resource: submitResourceDataCheckedNodes,
					roleId: this.addRolePermissionForm.id
				}

				//提交
				grant_role(params).then(() => {
					this.$msg.success({
						text: "授权成功"
					});
					this.submitLoading = false
					this.show = false
				}).catch(err => {
					this.$msg.error({
						text: "授权失败"
					});
					this.submitLoading = false
				})

			},
			cancle() {
				this.show = false
			}
		}
	}
</script>

<style scoped>
.con{
	padding: 20px 100px;
}
</style>
